<template>
    <div class="scroll-smooth">
        <NavBar />



        <v-carousel height="648px" cycle v-model="model" class="bg-primary">
            <v-carousel-item>
                    <div class="relative bg-white overflow-hidden ">
      <div class="max-w-7xl mx-auto">
        <div
          class="
            relative
            z-10
            pb-8
            bg-white
            sm:pb-16
            md:pb-20
            lg:max-w-2xl lg:w-full lg:pb-28
            xl:pb-32
          "
        >
          <svg
            class="
              hidden
              lg:block
              absolute
              right-0
              inset-y-0
              h-full
              w-48
              text-white
              transform
              translate-x-1/2
            "
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <!-- Small header -->

          <main
         
            class=" 
            
              md:mt-0
              mx-auto
              max-w-7xl
              px-6
              sm:mt-12 sm:px-6
              lg:px-8
              md:pt-20
              
            "
          >
            <div class="  sm:text-center lg:text-left">
              
              <h1
                class="
                animate__animated animate__backInLeft
                  text-4xl
                  tracking-tight
                  font-extrabold
                  text-gray-900
                  sm:text-4xl
                  md:text-5xl
                "
              >
                <span  class="block xl:inline ">Better Grades.</span>
              </h1>
              
              <h1
                class="
                animate__animated animate__backInLeft
                  text-4xl
                  tracking-tight
                  font-extrabold
                  text-gray-900
                  sm:text-3xl
                  md:text-4xl
                "
              >
                <span class="block xl:inline ">Higher Scores.</span>
              </h1>
              <h1
                class="
                animate__animated animate__backInLeft
                  text-4xl
                  tracking-tight
                  font-extrabold
                  text-gray-900
                  sm:text-2xl
                  md:text-3xl
                "
              >
                <span  class="block xl:inline ">Lower Stress.</span>
              </h1>
              
              <p
                class="
                animate__animated animate__backInLeft
                  mt-5
                  text-base text-gray-500
                  sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                  md:my-5 md:text-xl md:leading-relaxed
                  lg:mx-0
                "
              >
                With the only personalized packages in the business, our expert and experienced tutors have dedicated their careers to helping students and boosting scores! From Primary, Secondary into College life, our students enjoy fun, interactive and relevant lessons and are empowered to think beyond the confines of the classroom.
                We are currently offering special packages for <b> examination revisions </b> at all levels. Both for standardized or end of term or year, we also run STEM intensive revision sessions during long break holidays.

              </p>
              <div
                class="animate__animated animate__backInLeft mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start"
              >
                <div class="rounded-md">
                  <a
                    href="/be-a-student"
                    class="
                      w-full
                      flex
                      items-center
                      justify-center
                      px-8
                      py-3
                      my-2
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-black
                      bg-primary
                      hover:bg-secondary
                      md:py-4 md:text-lg md:px-10
                    "
                  >
                    Get A Tutor
                  </a>
                </div>
                <div class="sm:mt-0 sm:ml-3">
                  <a
                    href="/be-a-tutor"
                    class="
                      w-full
                      flex
                      items-center
                      justify-center
                      px-8
                      py-3
                      my-2
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-primary
                      bg-yellow-fade
                      hover:bg-yellow hover:text-white
                      md:py-4 md:text-lg md:px-10
                    "
                  >
                    Become A Tutor
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div class="animate__animated animate__bounceIn lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
         
          class=" h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          :src="require('@/assets/header.png')"
          alt=""
        />
      </div>
    </div>
            </v-carousel-item>
            <v-carousel-item :src="require('@/assets/tutor.png')">
                <v-sheet color="transparent" height="100%" tile>
                    <v-container style="height:100%; ">
                        <v-row style="height:100%;">
                          
                            <v-col  cols="7" class="d-flex flex-column-reverse justify-center "
                                style="height:100%; ">
                                <v-card color="#008e89" elevation="2" class="">
                                    <h1 class="
                                            animate__animated animate__headShake
                                            text-3xl
                                            pt-3
                                            px-5
                                            tracking-tight
                                            font-extrabold
                                            text-gray-900
                                            sm:text-4xl
                                            md:text-5xl
                                            ">
                                        <span class="block xl:inline ">Get A Tutor </span>
                                    </h1>
                                    <p class="
                                            animate__animated animate__headShake
                                            mt-5
                                            px-5
                                            text-base text-white
                                            sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                                            md:my-5 md:text-xl md:leading-relaxed
                                            lg:mx-0
                                        ">
                                        With the only personalized packages in the business, our expert and experienced
                                        tutors have dedicated their careers to helping students and boosting scores!
                                    </p>

                                    <div class="sm:mt-0 sm:ml-3 animate__animated animate__headShake">
                                        <a href="/be-a-student" class="
                      flex
                      items-center
                      justify-center
                      px-5
                      py-3
                      m-5
                      ml-2
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-primary
                      bg-yellow-fade
                      hover:bg-yellow hover:text-white
                      md:py-4 md:text-lg md:px-10
                    ">
                                            Get A Tutor
                                        </a>
                                    </div>
                                </v-card>

                            </v-col>
                            
                        </v-row>
                    </v-container>
                </v-sheet>
            </v-carousel-item>
            <v-carousel-item :src="require('@/assets/tutor2.png')">
                <v-container style="height:100%; ">
                    <v-row style="height:100%;">
                    <v-col  cols="7" class="d-flex flex-column-reverse justify-center "
                                style="height:100%; ">
                                <v-card color="#008e89" elevation="2" class="">
                                    <h1 class="
                                            animate__animated animate__headShake
                                            text-3xl
                                            pt-3
                                            px-5
                                            tracking-tight
                                            font-extrabold
                                            text-gray-900
                                            sm:text-4xl
                                            md:text-5xl
                                            ">
                                        <span class="block xl:inline ">Become A Tutor </span>
                                    </h1>
                                    <p class="
                                            animate__animated animate__headShake
                                            mt-5
                                            px-5
                                            text-base text-white
                                            sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto
                                            md:my-5 md:text-xl md:leading-relaxed
                                            lg:mx-0
                                        ">
                                       We are looking for passionate and committed individuals who want to become a tutor! You’ll need to have excelled academically and be currently studying or have completed tertiary studies, to join our team of tutors. We can’t wait to hear from you!
                                    </p>

                                    <div class="sm:mt-0 sm:ml-3 animate__animated animate__headShake">
                                        <a href="/be-a-tutor" class="
                      flex
                      items-center
                      justify-center
                      px-5
                      py-3
                      m-5
                      ml-2
                      border border-transparent
                      text-base
                      font-medium
                      rounded-md
                      text-primary
                      bg-yellow-fade
                      hover:bg-yellow hover:text-white
                      md:py-4 md:text-lg md:px-10
                    ">
                                            Become A Tutor
                                        </a>
                                    </div>
                                </v-card>

                            </v-col>
                    </v-row>
                </v-container>
            </v-carousel-item>
        </v-carousel>


        <!--Begining of yellow card -->
        <div class=" py-12 bg-primary">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="sm:text-center lg:text-left">
                    <p class="
            animate__animated animate__bounce
              mt-2
              text-3xl
              leading-8
              font-extrabold
              tracking-tight
              text-gray-900
              sm:text-4xl
            ">
                        What Makes Us Special
                    </p>
                </div>

                <div class="mt-10">
                    <dl class="
              space-y-10
              md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10
            ">
                        <div v-for="feature in features" :key="feature.name" class="relative">
                            <dt>
                                <div class="
                    absolute
                    flex
                    items-center
                    justify-center
                    h-12
                    w-12
                    rounded-md
                    bg-black
                    text-white
                  ">
                                    <v-icon v-scroll-reveal.reset large color="white darken-2">
                                        {{ feature.icon }}
                                    </v-icon>
                                </div>
                                <p v-scroll-reveal.reset class="ml-16 text-lg leading-6 font-medium text-gray-900">
                                    {{ feature.name }}
                                </p>
                            </dt>
                            <dd class="mt-2 ml-16 text-base text-white">
                                {{ feature.description }}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
        <!--End of yellow card -->

        <Testimonial></Testimonial>

        <!--Begining of Steps -->
        <div class="py-12 bg-white">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="sm:text-center lg:text-left">
                    <p v-scroll-reveal.reset class="
              mt-2
              text-3xl
              leading-8
              font-extrabold
              tracking-tight
              text-gray-900
              sm:text-4xl
            ">
                        How Do I Get Started?
                    </p>
                </div>

                <div class="mt-10">
                    <dl class="
              space-y-10
              md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10
            ">
                        <div href="#" v-scroll-reveal.reset class="
                group
                block
                max-w-xs
                mx-auto
                rounded-lg
                p-6
                bg-yellow-fade
                ring-1 ring-slate-900/5
                shadow-lg
                space-y-3
                
              ">
                            <div class="flex items-center space-x-3">
                                <div class="h-10 w-10 rounded-md text-white">
                                    <v-icon large color="black darken-2">
                                        mdi-numeric-1-circle
                                    </v-icon>
                                </div>
                                <h3 class="
                    ml-1
                    text-lg
                    leading-6
                    font-medium
                    text-gray-900 text-slate-900
                  ">
                                    STEP 1: Connect with us
                                </h3>
                            </div>
                            <ul>
                                <li>
                                    a) Visit our GET A TUTOR page, tell us who you are and what
                                    you need.
                                </li>
                                <li>b) Look out for an email from our team.</li>
                                <li>
                                    c) Select your tutoring package & final requirements. We’ll
                                    connect you with one of our fantastic tutors!
                                </li>
                            </ul>
                        </div>

                        <div href="#" v-scroll-reveal.reset class="
                group
                block
                max-w-xs
                mx-auto
                rounded-lg
                p-6
                bg-blue-fade
                ring-1 ring-slate-900/5
                shadow-lg
                space-y-3
                hover:bg-sky-500 hover:ring-sky-500
              ">
                            <div class="flex items-center space-x-3">
                                <div class="h-10 w-10 rounded-md text-white">
                                    <v-icon large color="black darken-2">
                                        mdi-numeric-2-circle
                                    </v-icon>
                                </div>
                                <h3 class="
                    ml-1
                    text-lg
                    leading-6
                    font-medium
                    text-gray-900 text-slate-900
                  ">
                                    STEP 2: Tutor Matched!
                                </h3>
                            </div>
                            <div>
                                <p>
                                    One of our learning consultants will match you to your ideal
                                    tutor, based on your needs and preferences. Approve your tutor
                                    and purchase your lesson package!
                                </p>
                                <p class="pl-3 font-bold">
                                    Everything is finalised and you eagerly await your first
                                    lesson!
                                </p>
                            </div>
                        </div>

                        <div v-scroll-reveal.reset class="
                group
                block
                max-w-xs
                mx-auto
                rounded-lg
                p-6
                bg-green-fade
                ring-1 ring-slate-900/5
                shadow-lg
                space-y-3
              ">
                            <div class="flex items-center space-x-3">
                                <div class="h-10 w-10 rounded-md text-white">
                                    <v-icon large color="black darken-2">
                                        mdi-numeric-3-circle
                                    </v-icon>
                                </div>
                                <h3 class="
                    mt-1
                    text-lg
                    leading-6
                    font-medium
                    text-gray-900 text-slate-900
                  ">
                                    STEP 3: Start Learning!
                                </h3>
                            </div>
                            <div>
                                At the first lesson, your tutor will identify gaps in knowledge
                                and establish learning goals. The tutor works alongside the
                                learner to help improve knowledge, confidence and marks!
                                <p class="font-bold pl-3">
                                    We are here every step of the way to ensure a great learning
                                    experience!
                                </p>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
        <!--End of Steps -->


        <Footer />
    </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import NavBar from "@/components/Navbar.vue";
//import Blog from "@/components/Blog.vue";
import Testimonial from "@/components/Testimonial.vue";

//const icon_user_group = UserGroupIcon;
//const icon_link = LinkIcon;
//const icon_library = LibraryIcon;
//const navigation = [
// { name: "Contact", href: "#" },
/// { name: "About", href: "#" },
//];

export default {
    components: {
        Footer,
        NavBar,
        //Blog,
        Testimonial
    },
    data() {
        return {
            items: [
                {
                    src: require('@/assets/header.png')
                },
                {
                    src: require('@/assets/tutor.png')
                },
                {
                    src: require('@/assets/tutor2.png')
                },

            ],
            features: [
                {
                    name: "Unique Tutors",
                    description:
                        "Your learning needs are unique. So is the way we match you to your tutor! We take great care in selecting tutors we think you’ll love. Every time!",
                    icon: "mdi-angle-acute ",
                },
                {
                    name: "Learning Support Pro’s",
                    description:
                        "We’re not just a tutoring company. We’re a learning company. And we’re here for you throughout your journey – from primary, through secondary, into university and beyond!",
                    icon: "mdi-call-split",
                },
                {
                    name: "We Care",
                    description:
                        "Our team is prompt. Our team is professional. Our team is personal. We commit ourselves to meeting individual needs! Contact us and see for yourself.",
                    icon: "mdi-account-heart",
                },
                {
                    name: "Out of the city? That’s nothing!",
                    description:
                        "We have a lot of tutors experienced with online teaching under our belt! After the COVID experience, we can say we know what we’re doing & we’re not going anywhere.",
                    icon: "mdi-alarm-bell",
                },
            ],
        };
    },
};
</script>
<style scoped>
/* unvisited link */
a:link {
    color: #323232 !important;
}

/* visited link */
a:visited {
    color: #323232;
}

/* mouse over link */
a:hover {
    color: #FFF;
}

/* selected link */
a:active {
    color: #008e89;
}

/*animations */
.animeclass {
    animation-duration: 2s;
    animation-name: slidein;
}

@keyframes slidein {
    from {
        margin-left: -50%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

.animeclass {
    animation-duration: 2s;
    animation-name: zoomin;
}

@keyframes zoomin {
    from {
        margin-left: -50%;

    }

    to {
        margin-left: 0%;

    }
}
</style>
